import { useEffect } from 'react';
import TopBar from '../components/Topbar/Topbar';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import TreatmentDetails from '../components/Treatment/TreatmentDetails';
import CTA from '../components/CTA/CTA';
import { useParams, Navigate } from "react-router-dom";
import { dynamicTitle, setDescription } from '../components/Helper/helper';


function TreatmentPage(props) {
  const { treatmentId } = useParams();
  const selectedTreatment = props.clinic.treatments.find(treatment => treatment.id === treatmentId);

  useEffect(()=>{
    if(selectedTreatment === undefined) {
      return;
    }
    dynamicTitle( props.clinic, `${selectedTreatment.name} - ${props.clinic.name}`);
    var description = `${selectedTreatment.name} is provided at ${props.clinic.name}, ${props.clinic.contact.address}. ${selectedTreatment.about_short}`;
    setDescription(description);
  },[props.clinic, selectedTreatment, ]);

  if(selectedTreatment === undefined) {
    return <Navigate to="/not-found" />;
  }

  return (
    <div>
      <TopBar clinic={props.clinic}/>
      <Header clinic={props.clinic}/>
      <TreatmentDetails treatments={props.clinic.treatments}/>
      <CTA clinic={props.clinic}/>
      <Footer clinic={props.clinic}/>
    </div>
  );
}
  
export default TreatmentPage;