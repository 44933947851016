export const pageName = {
    GALLERY : 'Gallery',
    ABOUTUS : 'About Us',
    APPOINTMENT: 'Make an appointment',
    DEFAULT : 'Home'
}
export const Mode = {
    DEBUG: false
}

export const Capability = {
    cap: [
        "name",
        "gallery",
        "social_media",
        "contact",
        "timing",
        "team",
        "treatments",
        "theme",
        "slider",
        "pages",
        "testimonials",
        "overrides",
        "google_tag",
        "features",
        "featured_page"
      ]
}