import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Elements
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class About extends Component {

    render() {
        let about = this.props.clinic.about;
        if(about.length === 0) {
            return <></>
        }
        let about_pic = "/img/about-pic.jpeg";
        if(this.props.clinic.about_pic !== undefined) {
            about_pic = this.props.clinic.about_pic;
        }
        let contact_url = "tel:" + this.props.clinic.contact.mobile[0]
        return (
            <div className="space-medium" style={{paddingBottom:"0px"}}>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12} xs={12} className='wow fadeInLeft' data-wow-duration="1.3s" data-wow-delay="0.3s">

                            <img src={about_pic} alt="clinic" className="polaroid"/>

                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className='wow fadeInRight' data-wow-duration="1.3s" data-wow-delay="0.3s">
                            <div className="about-section">
                                <h1>{this.props.clinic.name}</h1>
                                <div dangerouslySetInnerHTML={{__html:this.props.clinic.about}} style={{textAlign:'justify'}} className='about-list mb30'></div>
                                {this.props.clinic.holiday.enabled?
                                <p className='holiday-notice'>
                                    <b>Notice:</b> Clinic closed
                                    {this.props.clinic.holiday.start===this.props.clinic.holiday.end?
                                    <> on {this.props.clinic.holiday.start}. </>:<> from {this.props.clinic.holiday.start} to {this.props.clinic.holiday.end}. </>}
                                    Please avoid booking appointments. Thank you.
                                </p>:null}
                                <Link to="/appointment"><div className="btn btn-primary btn-sm">Appointment</div></Link>
                                <a href={contact_url}><div className="btn btn-default btn-sm">Call Us</div></a>
                                {this.props.as_section && !(this.props.clinic.team.length === 0)?
                                <Link to="/about"><div className="btn btn-secondary btn-sm">more</div></Link>
                                :null}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}