import JsonLD from './JsonLd';

class TeamPageJsonLD extends JsonLD {

    getEmployeeJson(clinic, host) {
        var employees = Array();
        for(var i=0; i < clinic.team.length; i++) {
            var member = clinic.team[i];
            employees.push({
                "@type": "Person",
                "name": member.name,
                "worksFor": clinic.name,
                "jobTitle": member.subhead,
                "image": host + member.avatar
            })
        }
        return employees
    }

    craftJsonLd() {
        let clinic = this.props.clinic;
        let about = clinic.about;
        var div = document.createElement("div");
        div.innerHTML = about;
        var about_text = div.textContent || div.innerText || "";
        var host = window.location.protocol + "//" + window.location.host;
        var about_pic = '/img/about-pic.jpeg';
        if(this.props.clinic.about_pic !== undefined) {
            about_pic = this.props.clinic.about_pic;
        }
        
        return {
            "@context": "http://schema.org",
            "@type": "Organization",
            "@id": host,
            "name": clinic.name,
            "logo": host + clinic.logo.path,
            "url": host,
            "image": host + about_pic,
            "description": about_text,
            "employees": this.getEmployeeJson(clinic, host)
          }
    }
}

export default TeamPageJsonLD;