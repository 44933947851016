import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Elements
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

//Style
import '../../styles/owl-carousel.css'

export default class Slider extends Component {
    componentDidMount() {
        const carousel = document.createElement("script");
        carousel.src = "js/owl-carousel.customV2.min.js";
        carousel.async = true;
        document.body.appendChild(carousel);
    }

    render() {
        if(this.props.clinic.slider.length === 0) {
            return <></>
        }
        return (
            <div className="slider">
                <div className="owl-carousel slider hero">
                {this.props.clinic.slider.map(slide =>(
                    <div className="item" key={slide.img}>
                        <div className="slider-img" style={{background:slide.background}}> <img src={slide.img} alt="slider page 1"/></div>
                        <Container>
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <div className="slider-captions">
                                        <h3 className="slider-title">{slide.title}</h3>
                                        <p className="slider-text d-none d-sm-block">{slide.text}</p>
                                        {slide.nobutton?null:
                                        <>
                                        <Link to="/appointment"><div className='btn btn-primary btn-sm d-none d-md-inline'>make an appointment</div></Link>
                                        {this.props.clinic.team.length > 0?
                                        <Link to="/about"><div className="btn btn-default btn-sm d-none d-md-inline">meet doctors</div></Link>:null}
                                        </>}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                ))}
                </div>
            </div>
        );
    }
}