import JsonLD from './JsonLd';

class ImageGalleryJsonLD extends JsonLD {

    getImageJson(clinic, host) {
        var images = Array()
        for(var i=0; i < clinic.gallery.general.length; i++) {
            var image = clinic.gallery.general[i];
            images.push({
                    "@type": "ImageObject",
                    "url": host + image
            })
        }
        return images
    }

    craftJsonLd() {
        let clinic = this.props.clinic;
        var host = window.location.protocol + "//" + window.location.host;       
        return {
            "@context": "https://schema.org",
            "@type": "ImageGallery",
            "name": "Image Gallery of " + clinic.name,
            "image": this.getImageJson(clinic, host)
        }
    }
}

export default ImageGalleryJsonLD;