import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
 /*-----------------------------------
    1. Body / Typography 
  -----------------------------------*/

  a:focus, a:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  h1:before, h1:after {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  h2:after, h2:before {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    border-top: 1px solid ${({ theme }) => theme.colors.primary};
  }

  /*-----------------------------------
  3. Buttons 
  -----------------------------------*/  

  .btn-default {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .btn-default:hover {
    background-color:  ${({ theme }) => theme.colors.primary_highlight};
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .btn-primary {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.text.secondary};
  }

  .btn-primary:hover {
    background-color: ${({ theme }) => theme.colors.secondary_highlight};
    color: ${({ theme }) => theme.colors.text.secondary};
  }

  .btn-link {
    color: ${({ theme }) => theme.colors.secondary};
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
  }

  .btn-link:hover {
    color: ${({ theme }) => theme.colors.secondary_highlight};
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary_highlight};
  }

    /*-------------------------
  4.5  Feature Block
  --------------------------*/

  .about-bg-block {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .feature-text {
    color: ${({ theme }) => theme.colors.text.primary};
}

.feature-title {
    color: ${({ theme }) => theme.colors.text.primary};
}

a.feature-link:focus, a.feature-link:hover {
  color: ${({ theme }) => theme.colors.text.primary};
}


  /*-------------------------
  9.2  Service / single / sidebar
  --------------------------*/

  .service-block:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  .service-icon {
    color: ${({ theme }) => theme.colors.primary};
  }



  .cta-section {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  .cta-section h3, .cta-section p {
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .team-meta {
    color: ${({ theme }) => theme.colors.primary};
  }

  .holiday-notice {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 400;
  }

  .st-pagination .pagination>li>a:focus,
  .st-pagination .pagination>li>a:hover,
  .st-pagination .pagination>li>span:focus,
  .st-pagination .pagination>li>span:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    border: ${({ theme }) => theme.colors.primary} 1px solid;
  }

  .st-pagination .pagination>.active>a,
  .st-pagination .pagination>.active>a:focus,
  .st-pagination .pagination>.active>a:hover,
  .st-pagination .pagination>.active>span,
  .st-pagination .pagination>.active>span:focus,
  .st-pagination .pagination>.active>span:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  .top-bar {
    background-color: ${({ theme }) => theme.colors.primary_shade};
    border-bottom: ${({ theme }) => theme.colors.background} 1px;
  }

 .call-text, .call-text strong {
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .social ul li a {
    font-size: 13px;
    padding: 12px 10px;
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .social ul li a:hover {
    color: ${({ theme }) => theme.colors.primary_highlight};
  }

  .header-wrapper {
    background-color: ${({ theme }) => theme.colors.background};
  }

  #navigation ul ul li a {
    background-color: ${({ theme }) => theme.colors.background};
    border-bottom: ${({ theme }) => theme.colors.text.foreground} 1px solid;
    color: ${({ theme }) => theme.colors.text.foreground};
  }

  #navigation > ul > li > a {
    color: ${({ theme }) => theme.colors.text.foreground};
  }

  #navigation ul ul li:hover > a,
  #navigation ul ul li a:hover {
    color: ${({ theme }) => theme.colors.text.primary};
    background-color: ${({ theme }) => theme.colors.primary_shade};
  }

  #navigation.small-screen #menu-button {
    background-color: ${({ theme }) => theme.colors.primary_shade};
  }

  #navigation.small-screen .submenu-button {
    border-left: 1px solid ${({ theme }) => theme.colors.primary_highlight};
  }

  #navigation.small-screen ul li {
    border-top: 1px solid ${({ theme }) => theme.colors.primary_highlight};
    background-color: ${({ theme }) => theme.colors.primary_shade};
  }

  #navigation.small-screen ul li a,
  #navigation.small-screen ul ul li a {
    color: ${({ theme }) => theme.colors.text.primary};
}

#navigation.small-screen #menu-button {
  color: ${({ theme }) => theme.colors.text.primary};
}

#navigation.small-screen #menu-button:after {
  border-top: 2px solid ${({ theme }) => theme.colors.text.primary};
  border-bottom: 2px solid ${({ theme }) => theme.colors.text.primary};
}

#navigation.small-screen #menu-button:before,
#navigation.small-screen .submenu-button:after,
#navigation.small-screen .submenu-button:before,
#navigation.small-screen #menu-button.menu-opened:after,
#navigation.small-screen #menu-button.menu-opened:before {
  background: ${({ theme }) => theme.colors.text.primary};
}

  /*-----------------------------------
    6. Slider 
  -------------------------------------*/
  .slider .owl-nav .owl-prev:hover {background-color: ${({ theme }) => theme.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary}; text-decoration: none; color: ${({ theme }) => theme.colors.text.primary};}

  .slider .owl-nav .owl-next:hover { background-color: ${({ theme }) => theme.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary}; text-decoration: none; color: ${({ theme }) => theme.colors.text.primary}; }

  .slider .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background-color: ${({ theme }) => theme.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }

  /*-----------------------------------
  8. Footer
  -------------------------------------*/
  .footer {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text.foreground};
  }
  .footer-title {
    color: ${({ theme }) => theme.colors.text.foreground};
  }

  .footer-widget ul li a {
    color: ${({ theme }) => theme.colors.text.foreground};
  }

  .footer-widget ul li a:hover {
    color: ${({ theme }) => theme.colors.primary_highlight};
  }

  .footer-widget ul li i {
    color: ${({ theme }) => theme.colors.text.foreground};
  }

  .tiny-footer  {
    background-color: ${({ theme }) => theme.colors.primary_shade};
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .tiny-footer a:visited {
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .tiny-footer a:focus, .tiny-footer a:hover {
    color: ${({ theme }) => theme.colors.primary_highlight};
  }

  .footer-social a span {
    color: ${({ theme }) => theme.colors.text.foreground};
  }

  .footer-social a span:hover {
    color: ${({ theme }) => theme.colors.text.foreground};
  }

  .footer-social a span.active {
    color: ${({ theme }) => theme.colors.text.foreground};
  }

  .footer-social.light a span {
    color: ${({ theme }) => theme.colors.primary};
  }

  .footer-social.light a span:hover {
    color: ${({ theme }) => 'black'};
  }

  .footer-social.light a span.active {
    color: ${({ theme }) => theme.colors.primary};
  }

  .about-list ul li:before {
    color: ${({ theme }) => theme.colors.primary};
  }

  .time-schedule-text {
    color: ${({ theme }) => theme.colors.text.foreground};
  }
`;