import { useEffect } from 'react';
import TopBar from '../components/Topbar/Topbar';
import Header from '../components/Header/Header';
import Slider from '../components/Slider/Slider';
import About from '../components/About/About';
import Features from '../components/Features/Features'
import FeaturedPage from '../components/Features/FeaturedPage'
import Treatment from '../components/Treatment/Treatment';
import Testimonials from '../components/Testimonials/Testimonials';
import Gallery from '../components/Gallery/Gallery';
import CTA from '../components/CTA/CTA';
import Map from '../components/Map/Map';
import Footer from '../components/Footer/Footer';
import WhatsAppButton from '../components/StickyWidgets/WhatsappButton';
import OrganisationJsonLD from '../components/SchemaMarkup/Organisation';
import { dynamicTitle, setDescription, setKeywords } from '../components/Helper/helper';

function Home(props) {

  useEffect(()=>{
    // set title
    dynamicTitle( props.clinic, props.clinic.name);

    // set description
    var description = '';
    if(props.clinic.meta && props.clinic.meta.description) {
      description = props.clinic.meta.description;
    } else {
      description = `${props.clinic.name} is one of the best dental clinic located at ${props.clinic.contact.address}. ` +
      "Root canal treatment(RCT), tooth extraction, fillings, crowns, and other dental procedures are available here.";
    }
    setDescription(description);

    // set keywords
    var keywords = '';
    if(props.clinic.meta && props.clinic.meta.keywords) {
      keywords = props.clinic.meta.keywords;
    }
    setKeywords(keywords)
  },[props.clinic]);

    return (
      <div>
        <OrganisationJsonLD clinic={props.clinic}/>
        <TopBar clinic={props.clinic}/>
        <Header clinic={props.clinic}/>
        <Slider clinic={props.clinic}/>
        <About clinic={props.clinic} as_section={true}/>
        <Features features={props.clinic.features}/>
        <Treatment treatments={props.clinic.treatments} theme={props.clinic.theme}/>
        <Testimonials testimonials={props.clinic.testimonials}/>
        <Gallery gallery={props.clinic.gallery} as_section={true} />
        <FeaturedPage pages={props.clinic.featured_page}/>
        <CTA clinic={props.clinic}/>
        <Map clinic={props.clinic}/>
        <Footer clinic={props.clinic}/>
        <WhatsAppButton clinic={props.clinic}/>
      </div>
    );
  }
  
  export default Home;