import React, { Component } from 'react';

// Elements
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom';

export default class CTA extends Component {

    render() {
        let cta_target = "/appointment";
        let cta_btn_caption = "Make an appointment";
        let cta_title = "Get the care you deserve, schedule your appointment now.";
        let cta_sub_title = "We offer a wide range of treatments and procedures to take your care.";
        if(this.props.clinic.overrides && this.props.clinic.overrides.cta) {
            if(this.props.clinic.overrides.cta.target) {
                cta_target = this.props.clinic.overrides.cta.target;
            }
            if(this.props.clinic.overrides.cta.btn_text) {
                cta_btn_caption = this.props.clinic.overrides.cta.btn_text;
            }
            if(this.props.clinic.overrides.cta.title) {
                cta_title = this.props.clinic.overrides.cta.title;
            }
            if(this.props.clinic.overrides.cta.sub_title) {
                cta_sub_title = this.props.clinic.overrides.cta.sub_title;
            }
        }

        return (
        <div className="cta-section">
            <Container>
                <Row>
                    <Col lg={8} md={8} sm={12} xs={12}>
                        <h3 className="cta-title">{cta_title}</h3>
                        <p>{cta_sub_title}</p>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12}>
                        <Link to={cta_target}><div className="cta-btn btn btn-primary btn-lg">{cta_btn_caption}</div></Link>
                    </Col>
                </Row>
            </Container>
        </div>
        );
    }
}