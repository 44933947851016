import { Component } from 'react';

class GoogleTagManager extends Component {
  constructor(props) {
    super(props);
    this.script1 = null;
    this.script2 = null;
  }

  componentDidMount() {
    this.addScripts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.trackingId !== this.props.trackingId) {
      this.removeScripts();
      this.addScripts();
    }
  }

  componentWillUnmount() {
    this.removeScripts();
  }

  addScripts() {
    const { trackingId } = this.props;
    if (trackingId) {
      // Create the first script tag
      this.script1 = document.createElement('script');
      this.script1.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
      this.script1.async = true;
      document.head.appendChild(this.script1);

      // Create the second script tag
      this.script2 = document.createElement('script');
      this.script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${trackingId}');
      `;
      document.head.appendChild(this.script2);
    }
  }

  removeScripts() {
    if (this.script1) {
      document.head.removeChild(this.script1);
      this.script1 = null;
    }
    if (this.script2) {
      document.head.removeChild(this.script2);
      this.script2 = null;
    }
  }

  render() {
    return null;
  }
}

export default GoogleTagManager;
