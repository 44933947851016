import React, { Component } from 'react';

// Elements
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class Header extends Component {

    render() {
        if(this.props.pages === undefined) {
            return <></>
        }
        if(this.props.pages.length === 0) {
            return <></>
        }
        return (
        <div className="space-medium">
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="section-title">
                            <h2>Featured Pages</h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {this.props.pages.map(page =>(
                    <Col lg={3} md={4} sm={6} xs={12} key={page.url}>
                        <a href={page.url}>
                        <div className="service-block wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.3s">
                            <div className="service-icon" >
                                <img alt='cover_image' src={page.cover} className='icon'/>
                            </div>
                            <div>
                                <h3 style={{fontSize:"20px"}}>{page.title}</h3>
                            </div>
                        </div>
                        </a>
                    </Col>))}
                </Row>
            </Container>
        </div>
        );
    }
}