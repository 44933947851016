import React from "react";
import { useParams } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/esm/Container";

const TreatmentDetails = ({ treatments }) => {

    const { treatmentId } = useParams();
    const selectedTreatment = treatments.find(treatment => treatment.id === treatmentId);
    let treatmentImg = "/img/treatment.jpeg";
    if( selectedTreatment.picture ) {
        treatmentImg = selectedTreatment.picture;
    }
    return (
        <Container className="treatment-container" > 
        <Row className="justify-content-md-center treatment-row">
            <Col md={12} sm={12} xs={12}>
                <h1>{selectedTreatment.name}</h1>
            </Col>
            <Col md={5} sm={12} xs={12}>
                <img src={treatmentImg} alt="treatment" className="polaroid" />
            </Col>
            <Col md={7} sm={12} xs={12}>
            <div className="treatment-details" >
                <p style={{textAlign:'justify'}}>{selectedTreatment.about_long}</p>
            </div>
            </Col>
        </Row>
        </Container>
    )
}

export default TreatmentDetails;