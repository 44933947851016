import React, { Component } from 'react';

// Elements
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class Team extends Component {

    render() {
        if(this.props.team.length === 0) {
            return <></>;
        }
        return (
            <div className="space-medium">
                <Container>
                    <Row>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="section-title">
                                <h2>Our Team</h2>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        {this.props.team.map(member => (
                        <Col lg={6} md={6} sm={6} xs={12} key={member.name}>
                            <div className="team-block wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.3s">
                                <Row>
                                    <Col lg={{span:4,offset:0}} md={{span:4,offset:0}} sm={{span:6,offset:1}} xs={{span:6,offset:3}}>
                                        <img src={member.avatar} alt="dentist" className="rounded-circle"/>
                                        <div className="team-content mt20">
                                            <div className="footer-social light">
                                                {"facebook" in member.social_media ? <a href={member.social_media.facebook} target={"_blank"} rel="noreferrer"><span><i className="fa fa-facebook"></i></span></a> : null}
                                                {"instagram" in member.social_media ? <a href={member.social_media.instagram} target={"_blank"} rel="noreferrer"><span><i className=" fa fa-instagram"></i> </span></a> : null}
                                                {"linkedin" in member.social_media ? <a href={member.social_media.linkedin} target={"_blank"} rel="noreferrer"><span><i className="fa fa-linkedin"></i></span></a> : null}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={8} md={8} sm={8} xs={12} style={{textAlign:'justify'}}>
                                        <h3 className="team-title">{member.name}</h3>
                                        <span className="team-meta">{member.subhead}</span>
                                        <p style={{textAlign:'justify'}}>{member.about}</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col> ))}
                    </Row>
                </Container>
            </div>
        );
    }
}