import React, { Component } from 'react'
import '../../styles/loader.css';

export default class Loader extends Component {
    state = {
        visible: true
    }
    hideLoader() {
        this.setState({visible:false});
    }
    componentDidMount() {
        if(this.props.timeout !== undefined) {
            window.setTimeout(this.hideLoader.bind(this), this.props.timeout);
        }
    }
    render() {
        if( this.state.visible ) {
            return <div className="loading">Loading...</div>
        } else{
            return <></>
        }
    }
}
  