function WhatsAppButton(props) {
    const has_mobile = props.clinic.contact.mobile.length > 0;
    if(has_mobile) {
        let contact_no = props.clinic.contact.mobile[0];
        let url = "https://wa.me/91" + contact_no;
        return (<>
            <a href={url} className="floating-button" target="_blank" rel="noreferrer">
                <img src="/img/whatsapp.gif" />
            </a>
        </>)
    }
    return (<></>);
  }
  
  export default WhatsAppButton;