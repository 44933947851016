import { useEffect } from 'react';
import TopBar from '../components/Topbar/Topbar';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import About from '../components/About/About';
import Team from '../components/About/Team';
import CTA from '../components/CTA/CTA';

import { dynamicTitle, setDescription } from '../components/Helper/helper';
import { pageName } from '../components/Helper/constants';
import TeamPageJsonLD from '../components/SchemaMarkup/TeamPage';

function AboutPage(props) {
  useEffect(()=>{
    dynamicTitle( props.clinic, `${pageName.ABOUTUS} - ${props.clinic.name}`);
    var team_list = "";
    for(var idx in props.clinic.team) {
      var dentist = props.clinic.team[idx];
      team_list += `${dentist.name} - ${dentist.subhead}`;
    }
    var description = `${props.clinic.name} is one of the best dental clinic located at ${props.clinic.contact.address}.`+
                      `Dentist available at this clinic includes, ${team_list}`;
    setDescription(description);
  },[props.clinic]);
    return (
      <div>
        <TeamPageJsonLD clinic={props.clinic}/>
        <TopBar clinic={props.clinic}/>
        <Header clinic={props.clinic}/>
        <About clinic={props.clinic}/>
        <Team team={props.clinic.team}/>
        <CTA clinic={props.clinic}/>
        <Footer clinic={props.clinic}/>
      </div>
    );
  }
  
  export default AboutPage;