import React, { Component } from 'react';

export default class MapSection extends Component {

    render() {
        if(!this.props.clinic.location || this.props.clinic.location.length === 0 ) {
            return <></>
        }
        let url = this.props.clinic.location;
        return (
                <iframe title="Clinic Map"
                        src={url}
                        style={{border:0, width:"100%",minHeight:'400px',marginBottom:'-12px'}}
                        allowFullScreen={""}
                        loading={"lazy"}
                        referrerPolicy={"no-referrer-when-downgrade"}></iframe>
        );
    }
}