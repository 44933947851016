import React, { Component } from 'react';

class JsonLD extends Component {
  componentDidMount() {
    this.addJSONLDScript();
  }

  componentWillUnmount() {
    this.removeJSONLDScript();
  }

  craftJsonLd() {
    return {}
  }

  addJSONLDScript() {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(this.craftJsonLd());
    script.id = "schema_json_ld"
    document.head.appendChild(script);
  }

  removeJSONLDScript() {
    const script = document.getElementById("schema_json_ld");
    if (script) {
      script.remove();
    }
  }

  render() {
    return null;
  }
}

export default JsonLD;