import React, { Component } from 'react';

// Elements
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class Testimonials extends Component {

    componentDidMount() {
        const carousel = document.createElement("script");
        carousel.src = "js/owl-carousel.customV2.min.js";
        carousel.async = true;
        document.body.appendChild(carousel);
    }

    render() {
        if(this.props.testimonials.length === 0) {
            return <></>;
        }
        return (
        <div className="space-medium bg-light">
            <Container>
                <Row>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="section-title">
                            <h1>What Our Patient Says</h1>
                        </div>
                    </div>
                </Row>
                <div className="testimonial-carousel">
                    <div className="owl-carousel slider testimonial">
                        {this.props.testimonials.map(review => (
                        <div className="item" key={review.name}>
                            <Col lg={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} sm={12} xs={12}>
                                <div className="">
                                    <div className="testimonial-content">
                                        <p className="testimonial-text">“{review.message}”</p>
                                        <div className="">
                                            <div className="testimonial-pic"> <img src={review.avatar} alt="" className="rounded-circle"/></div>
                                            <div className="testimonial-meta">
                                                <span>-{review.name}</span> </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>))}
                    </div>
                </div>
            </Container>
        </div>
        );
    }
}