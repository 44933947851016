import React, { Component } from 'react'
import TopBar from '../components/Topbar/Topbar';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Custom from '../components/Custom/Custom';
import CTA from '../components/CTA/CTA';
import {Mode} from '../components/Helper/constants';
import * as gum_treatemnt from '../testdata/custom_page.json';
import * as root_treatment from '../testdata/custom_page_2.json';
import { Navigate } from 'react-router-dom';
import Loader from "../components/Helper/Loader";

import { dynamicTitle, setDescription, setKeywords } from '../components/Helper/helper';
import { useParams } from "react-router-dom";
import {get_request_option} from "../components/Helper/helper";


export const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();

  return <WrappedComponent {...props} params={params} />;
};

class CustomPage extends Component {

  constructor(props){
    super(props);
    this.state = {
      redirect : false,
      loading : true,
      page : undefined,
      tag: props.params.pageTag
    }
  }
  updateTitle() {
    if( this.state.page.name !== undefined ) {
      dynamicTitle( this.props.clinic, `${this.state.page.name} -  ${this.props.clinic.name}`);
      setDescription(this.state.page.description);
      if(this.state.page.keywords !== undefined) {
        setKeywords(this.state.page.keywords)
      }
    }
  }

  componentDidMount() {
    var pageTag = this.props.params.pageTag;
    if(Mode.DEBUG) {
      if(pageTag !== 'gum-treatment' && pageTag !== 'root-treatment') {
        this.setState({ redirect : true});
        return;
      }
      fetch("/api/page", get_request_option("POST", JSON.stringify({page:pageTag})))
          .then(response => response.json())
          .then(page => console.log('DEBUG=True'))
          .catch(err => {
            if(pageTag === "gum-treatment") {
              this.setState({page:gum_treatemnt});
            } else {
              this.setState({page:root_treatment});
            }
            this.setState({loading:false});
            this.updateTitle();
          });
    } else {
      fetch("/api/page", get_request_option("POST", JSON.stringify({page:pageTag})))
          .then(response => response.json())
          .then(page => {
              if(page.status === 'OK') {
                this.setState({page: page});
                this.updateTitle();
              } else {
                this.setState({ redirect : true});
                return;
              }
              this.setState({loading: false});
          })
          .catch( err => console.log(err));
    }
    this.setState({tag:this.props.params.pageTag});
  }


  render() {
    if(this.props.params.pageTag !== this.state.tag) {
      this.componentDidMount();
    }
    if(this.state.redirect) {
      return <Navigate to="/not-found" />;
    }

    if(this.state.loading) {
      return <Loader />
    }

    return (
      <div>
        <TopBar clinic={this.props.clinic}/>
        <Header clinic={this.props.clinic}/>
        <Custom page={this.state.page}/>
        <CTA clinic={this.props.clinic}/>
        <Footer clinic={this.props.clinic}/>
      </div>
    );
  }

}

export default withRouter(CustomPage);