import { useEffect } from 'react';
import TopBar from '../components/Topbar/Topbar';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Gallery from '../components/Gallery/Gallery';
import CTA from '../components/CTA/CTA';

import { dynamicTitle, setDescription } from '../components/Helper/helper';
import { pageName } from '../components/Helper/constants';
import ImageGalleryJsonLD from '../components/SchemaMarkup/ImageGallery';

function GalleryPage(props) {
  useEffect(()=>{
    dynamicTitle( props.clinic, `${pageName.GALLERY} -  ${props.clinic.name}`);
    var description = `Photos from ${props.clinic.name}. It is one of the best dental clinic located at ${props.clinic.contact.address}.`+
                      "Dental procedures like root canal treatment, tooth extraction, fillings, crowns and other advanced procedures are available."
    setDescription(description);
  },[props.clinic]);
    return (
      <div>
        <ImageGalleryJsonLD clinic={props.clinic}/>
        <TopBar clinic={props.clinic}/>
        <Header clinic={props.clinic}/>
        <Gallery gallery={props.clinic.gallery}/>
        <CTA clinic={props.clinic}/>
        <Footer clinic={props.clinic}/>
      </div>
    );
  }
  
  export default GalleryPage;