import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Elements
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class Header extends Component {
    componentDidMount() {
        const menuMakerScript = document.createElement("script");
        menuMakerScript.src = "/js/menumaker.min.js";
        menuMakerScript.async = true;
        document.body.appendChild(menuMakerScript);

        const stickyScript = document.createElement("script");
        stickyScript.src = "/js/jquery.sticky.min.js";
        stickyScript.async = true;
        document.body.appendChild(stickyScript);
    }

    render() {
        return (
        <div className="header-wrapper">
            <Container>
                <Row>
                    <Col lg={4} md={4} sm={12} xs={12}>
                        <Link to="/">
                            <img src={this.props.clinic.logo.path} alt="clinic logo" style={{maxHeight:'80px'}}/>
                        </Link>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={12}>
                        <div className="navigation">
                            <div id="navigation">
                                <ul>
                                    {this.props.clinic.team.length === 0? null:
                                    <li><Link to="/about">About us</Link></li>}
                                    <li><Link to="/appointment">Appointment</Link></li>
                                    {this.props.clinic.gallery.general.length !== 0? <li><Link to="/gallery">Gallery</Link></li> : null }
                                    <li className="has-sub"><a title="Services">Services</a>
                                        <ul>
                                            {this.props.clinic.treatments.map(service => (
                                                <li key={service.name}>
                                                    <a href={service.url?service.url:`/treatments/${service.id}`}>
                                                    {service.name_short}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                    {this.props.clinic.pages.filter(category => category.name !== 'UNCAT').map(category =>(
                                        <li className={category.type==='category'?"has-sub":null} key={category.name}>
                                            {category.type==='category'?
                                            <a title={category.name}>{category.name}</a>:
                                            <Link to={category.url}>{category.name}</Link>}
                                            {category.items && category.items.length>0?
                                            <ul>
                                                {category.items.map(item => (
                                                <li key={item.name}>
                                                    <a href={item.url}>
                                                    {item.name}
                                                    </a>
                                                </li>
                                            ))}
                                            </ul>
                                            :null}
                                        </li>
                                    ))}
                                    {(this.props.clinic.overrides && this.props.clinic.overrides.dashboard_link) || !this.props.clinic.overrides?
                                    <li><a href="/dashboard/login">Dashboard</a></li>:null}
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        );
    }
}