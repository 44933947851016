import { useEffect } from 'react';
import TopBar from '../components/Topbar/Topbar';
import Header from '../components/Header/Header';
import AppointmentForm from '../components/AppointmentForm/AppointmentForm';
import Footer from '../components/Footer/Footer';

import { dynamicTitle, setDescription } from '../components/Helper/helper';
import { pageName } from '../components/Helper/constants';
import ContactPageJsonLD from '../components/SchemaMarkup/ContactPage';


function Appointment(props) {
  useEffect(()=>{
    dynamicTitle( props.clinic, `${pageName.APPOINTMENT} - ${props.clinic.name}`);
    var description = `Make an appointment at ${props.clinic.name} located at ${props.clinic.contact.address}  for dental procedures`+
                       "like root canal treatment, tooth extraction, fillings, crowns and many advanced procedures.";
    setDescription(description);
  },[props.clinic]);
    return (
      <div>
        <ContactPageJsonLD clinic={props.clinic}/>
        <TopBar clinic={props.clinic}/>
        <Header clinic={props.clinic}/>
        <AppointmentForm clinic={props.clinic}/>
        <Footer clinic={props.clinic}/>
      </div>
    );
  }
  
  export default Appointment;