import { useEffect } from 'react';
import TopBar from '../components/Topbar/Topbar';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Section404 from '../components/Error/404';
import CTA from '../components/CTA/CTA';

import { dynamicTitle } from '../components/Helper/helper';

function Error404Page(props) {
  useEffect(()=>{
    dynamicTitle( props.clinic, `Page Not Found - ${props.clinic.name}`);
  },[props.clinic]);
    return (
      <div>
        <TopBar clinic={props.clinic}/>
        <Header clinic={props.clinic}/>
        <Section404 />
        <CTA clinic={props.clinic}/>
        <Footer clinic={props.clinic}/>
      </div>
    );
  }
  
  export default Error404Page;