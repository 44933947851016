import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Elements
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class Footer extends Component {

    render() {
        let address = "--";
        const has_address = this.props.clinic.contact.address.length > 0;
        if(has_address) {
            address = this.props.clinic.contact.address;
        }
        const mobiles = this.props.clinic.contact.mobile;
        let email = "--"
        const has_email = this.props.clinic.contact.email.length > 0
        if(has_email) {
            email = this.props.clinic.contact.email;
        }
        const has_facebook = "facebook" in this.props.clinic.social_media;
        const has_instagram = "instagram" in this.props.clinic.social_media;
        const has_youtube = "youtube" in this.props.clinic.social_media;
        return (
        <>
        <div className="footer">
            <Container>
                <Row>
                    <Col lg={4} md={4} sm={4} xs={12}>
                        <div className="footer-widget">
                            <div className="mb30"><img src={this.props.clinic.logo.path} alt="clinic logo" className="img-responsive"/></div>
                            <p>{ address }</p>
                            {this.props.clinic.team.length === 0? null:
                            <Link to="/about"><div className="btn btn-default btn-sm">meet our team</div></Link>
                            }
                        </div>
                    </Col>
                    <Col lg={2} md={2} sm={3} xs={12}>
                        <div className="footer-widget">
                            <h3 className="footer-title">Quick Links</h3>
                            <ul>
                                {this.props.clinic.team.length === 0? null:
                                <li><Link to="/about"><div>About us</div></Link></li> }
                                <li><a href="/#treatments">Our Services</a></li>
                                {this.props.clinic.gallery.general.length !== 0?
                                <li><Link to="/gallery"><div>Gallery</div></Link></li>: null}
                            </ul>
                        </div>
                    </Col>
                    <Col lg={2} md={2} sm={3} xs={12}>
                        <div className="footer-widget">
                            <h3 className="footer-title">Schedule</h3>
                            {
                                this.props.clinic.timing.map(_time => (
                                    <div className="time-schedule" key={_time.days}>
                                        <p className="time-schedule-text">{_time.days}</p>
                                        <span>{_time.value}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={4} xs={12}>
                        <div className="footer-widget">
                            <h3 className="footer-title">Contact Information</h3>
                            <div className="">
                                <ul>
                                    {mobiles.map(mobile => ( <li key={mobile}><i className="fa fa-phone"></i>{mobile}</li> ))}
                                    <li><i className="fa fa-envelope-open"></i>{ email }</li>
                                </ul>
                            </div>
                            <div className="footer-social">
                                {has_facebook ? <a href={this.props.clinic.social_media.facebook} target={"_blank"} rel="noreferrer"><span><i className="fa fa-facebook"></i></span></a>  : null}
                                {has_instagram ? <a href={this.props.clinic.social_media.instagram} target={"_blank"} rel="noreferrer"><span><i className="fa fa-instagram"></i> </span></a> : null}
                                {has_youtube ? <a href={this.props.clinic.social_media.youtube} target={"_blank"} rel="noreferrer"><span><i className=" fa fa-youtube"></i> </span></a> : null}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className="tiny-footer">
            <Container>
                <Row>
                    <Col md={12}>All Rights Reserved - {this.props.clinic.name} | Powered by <a href={"https://dentostack.com"} target={"_blank"}>Dentostack &#174;</a></Col>
                </Row>
            </Container>
        </div>
        </>
        );
    }
}
