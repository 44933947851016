const craftTitle = (clinic, base_name ) => {
  var title = base_name;
  if(clinic.overrides && clinic.overrides.page_title) {
    title += clinic.overrides.page_title;
  } else {
    title += ` | Dental clinic in ${clinic.city}, ${clinic.state}`;
  }
  return title;
}

export const dynamicTitle = (clinic, base_name) => {
var title = craftTitle(clinic, base_name);
title = title ? title : 'Home';
window.document.title = title;
};

export const setDescription = desc => {
  const el = document.querySelector("meta[name='description']");
  el.setAttribute('content',desc);
}

export const setKeywords = keywords => {
  const el = document.querySelector("meta[name='keywords']");
  el.setAttribute('content', keywords);
}

export const get_request_option = (method, json) => {
    return {
      method: method,
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain, */*' },
      body: json,
    }
}

export const getToday = () => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth()+1;
  var yyyy = today.getFullYear();
  if(dd<10){
    dd='0'+dd
  }
  if(mm<10){
    mm='0'+mm
  }
  return  yyyy+'-'+mm+'-'+dd;
}
