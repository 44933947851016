import JsonLD from './JsonLd';

class ContactPageJsonLD extends JsonLD {
    craftJsonLd() {
        let clinic = this.props.clinic;
        let contact = clinic.contact;
        let about = clinic.about;
        var div = document.createElement("div");
        div.innerHTML = about;
        var about_text = div.textContent || div.innerText || "";
        var host = window.location.protocol + "//" + window.location.host;
        var about_pic = '/img/about-pic.jpeg';
        if(this.props.clinic.about_pic !== undefined) {
            about_pic = this.props.clinic.about_pic;
        }
        return {
            "@context": "https://schema.org",
            "@type": "ContactPage",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": host + "/appointment"
            },
            "publisher": {
              "@type": "LocalBusiness",
              "@id": host,
              "name": clinic.name,
              "logo": host + clinic.logo.path,
              "telephone": contact.mobile[0],
              "email": contact.email,
              "url": host,
              "image": host + about_pic,
              "description": about_text,
              "address": {
                "@type": "PostalAddress",
                "streetAddress": contact.address,
                "addressLocality":  clinic.city,
                "addressRegion": clinic.state
              }
            }
          }
    }
}

export default ContactPageJsonLD;